<template>
  <div class="card">
    <div class="card-body pt-0 mt-5 steps">
      <div class="row">
        <div class="col-md-5" v-if="active == 0">
            <div class="alert alert-primary d-flex align-items-center p-5 mb-10">
              <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                  <inline-svg src="/media/icons/duotune/general/gen048.svg" />
              </span>
              <div class="d-flex">
                <h4 class="mt-3 text-primary"> {{ constants.artSku.SAMPLE_ART_FILE }} </h4>
                <span class="ms-2">
                <a href="javascript:void()" class="mt-1 btn btn-sm btn-flex btn-light-primary btn-active-primary fw-bolder" @click="downloadSampleFile('xlsx')">
                <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1"> <i class="bi bi-file-earmark-arrow-down fs-4"></i> </span>
                {{constants.general.DOWNLOAD_EXCEL}}
                </a>
                </span>

                <span class="ms-2">
                <a href="javascript:void()" class="mt-1 btn btn-sm btn-flex btn-light-primary btn-active-primary fw-bolder" @click="downloadSampleFile('csv')">
                <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1"> <i class="bi bi-file-earmark-arrow-down fs-4"></i> </span>
                {{constants.general.DOWNLOAD_CSV}}
                </a>
                </span>
              </div>
            </div>
        </div>
      </div>
      <el-steps :active="active" finish-status="success">
        <el-step :title="constants.artSku.ART_IMPORT" />
        <el-step :title="constants.artSku.ART_SKU_STATISTICS" />
        <el-step :title="constants.general.FINISH" class="text-end" />
      </el-steps>
      <el-form
        @submit.prevent="importArtSkuData()"
        :model="artSkuImportFormData"
        :rules="rules"
        ref="artSkuFormRef"
        class="w-100 mt-5"
      >  
      <div class="row text-center" v-if="active == 0">
        <div class="col-md-12 fv-row">
          <label class="fs-6 fw-bold mb-2">{{ constants.artSku.SELECT_ART_EXCEL }}</label>
          <el-form-item prop="artSkuFile" class="import-form-item">
          <el-upload
            v-model="artSkuImportFormData.artSkuFile"
            :auto-upload="false"
            :limit="2"
            :multiple="false"
            :on-remove="handleRemove"
            v-on:change="handleChange"
            :file-list="fileList"
            drag
            class="upload-exel"
            action=""
            >
            <div class="fv-row">
              <div class="dropzone" id="kt_dropzonejs_example_1">
                  <div class="dz-message needsclick">
                      <i class="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                      <div class="ms-4">
                          <h3 class="fs-5 fw-bolder text-gray-900 mb-1 mt-4" v-html="constants.general.UPLOADFILEPLACETEXT"></h3>
                      </div>
                  </div>
              </div>
            </div>
          </el-upload>
          </el-form-item>
        </div>
      </div>

      <span class="row mt-10" v-if="active == 1" v-loading="loading">  
        <div class="col-md-3 mb-1">
          <div class="col bg-light-primary px-6 py-8 rounded-2 me-7  mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
              <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
            </span>
            <span class="text-primary fw-bold fs-6"> {{ constants.general.TOTAL_ROWS }} <h1 class="text-primary"><strong>{{ totalArtSkuCount }}</strong></h1></span>
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div class="col bg-light-success px-6 py-8 rounded-2 me-7  mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
              <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
            </span>
            <span class="text-success fw-bold fs-6"> {{ constants.artSku.TOTAL_ART_SKU_IMPORT }} <h1 class="text-success"><strong>{{ totalImportArtSkuCount }}</strong></h1></span>
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div class="col bg-light-danger px-6 py-8 rounded-2 me-7  mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
            </span>
            <span class="text-danger fw-bold fs-6"> {{ constants.general.SKIPPED_ROWS+'('+constants.artSku.TOTAL_EXIST_ART_SKU_IMPORT+')' }} <h1 class="text-danger"><strong>{{ (totalArtSkuExistFoundCount+totalArtSkuNotFoundCount) }}</strong></h1></span>
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div class="col bg-light-danger px-6 py-8 rounded-2 me-7  mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
            </span>
            <span class="text-danger fw-bold fs-6"> {{ constants.artSku.DUPLICATE_ART_SKU }} <h1 class="text-danger"><strong>{{ totalDuplicateArtSkuFound }}</strong></h1></span>
          </div>
        </div>
        <div class="d-flex align-items-center">
            <el-checkbox v-model="updateDataCheckbox" label="Check this checkbox if you want to update existing art sku data" />
        </div>
      </span>

      <span v-if="active > 1" v-loading="loading">  
        <el-row>
          <el-col :md="8"></el-col>
          <el-col :md="7" class="text-center">
          <div class="alert alert-success d-flex flex-column flex-sm-row p-5 mb-10">
              <span class="svg-icon svg-icon-2hx svg-icon-success me-4 mb-5 mb-sm-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"></path>
                  <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="currentColor"></path>
                </svg>
              </span>
              <div class="d-flex flex-column pe-0 pe-sm-10">
                  <h4 class="mb-2 text-center">{{ constants.general.SUCCESS }}</h4>
                  <span class="text-center">{{ constants.artSku.ART_IMPORT_MESSAGE }}</span>
              </div>
          </div>
            <button type="button" size="large" class=" btn btn-primary" @click.prevent="$router.push({ name: 'art-SKU'})">{{constants.artSku.ART_IMPORT_REDIRECT_BTN}}</button>
        </el-col>
        </el-row>
      </span>
      <div class="col-md-12 mt-10 d-flex justify-content-between">
        <div>
          <button type="button" size="large" v-bind:class="btnHide" :disabled="disabled" v-if="active > 0" class=" btn btn-primary me-2" :loading="loading" @click="back">{{constants.general.PREVIOUS}}</button>
        </div>
        <div>
          <button type="button" size="large" v-bind:class="btnHide" class="btn btn-primary me-2" @click="importArtSkuData(artSkuFormRef)">{{constants.general.NEXT}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
          <button type="button" size="large" class=" btn btn-secondary me-2" v-bind:class="btnHide" @click.prevent="$router.push({ name: 'art-SKU'})">{{constants.general.CANCEL}}</button>
        </div>
      </div>
      <div class="col-md-12 mt-5 bg-light p-2" v-if="active == 0">
        <span class="text-danger rounded-1">{{ constants.artSku.IMPORT_ART_SKU_HELP }}</span>
      </div>
    </el-form>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import globalConstant from "@/core/data/globalConstant.js";
import { formatText } from "@/core/helpers/common";
import useEventBus from "../../composable/useEventBus";
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import axios from "axios";

export default {
  name: "art-sku-import",
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const route = useRoute();
    const router = useRouter();
    const btnHide = ref();
    const loading = ref(false);
    const file = ref();
    const active = ref(0);
    const disabled = ref(true);
    const nextBtnDisabled = ref(false);
    const artSkuFormRef = ref(null);
    const totalArtSkuCount = ref(0);
    const totalImportArtSkuCount = ref(0);
    const totalArtSkuNotFoundCount = ref(0);
    const totalArtSkuExistFoundCount = ref(0);
    const totalDuplicateArtSkuFound = ref(0);
    const updateDataCheckbox = ref(false)
    const constants = globalConstant;
    const fileList = ref([]);

    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "back"){
        router.push('/art-sku');
      }
    })

    const artSkuImportFormData = ref({
      artSkuFile: "",
    });

    const rules = ref({
      artSkuFile: [ { required: true, message: constants.general.EXCELFILEREQUIRED, trigger: "change" } ],
    });

    const downloadSampleFile = (extension) => {
      window.open(process.env.VUE_APP_API_URL+'../../../sample_art_sku.'+extension, '_blank');
    }

    const handleRemove = (e) => {
      artSkuImportFormData.value.artSkuFile = '';
    }

    const handleChange = (e) => {
      if(fileList.value.length > 1){
        fileList.value.splice(0, 1);
      }
      file.value = e.raw;
      artSkuImportFormData.value.artSkuFile = e.raw;
    }
    
    const back = () => {
      nextBtnDisabled.value = false;
      if (active.value-- < 2) active.value = 0
    }

    const importArtSkuData = async (formEl) => {
      if (!artSkuFormRef.value) {
        return;
      }
      artSkuFormRef.value.validate((valid) => {
        if (!valid) return false;
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
          }
        if(active.value == 0){
          loading.value = true;
          disabled.value = true;
          let formData = new FormData();
          formData.append('file', file.value);
          formData.append('steps', active.value);
          axios.post('art-sku/import', formData, config)
          .then(({ data }) => {
              if(data.data){
                totalArtSkuCount.value = data.data.totalArtSkuCount;
                totalImportArtSkuCount.value = data.data.totalImportArtSkuCount;
                totalArtSkuNotFoundCount.value = data.data.totalArtSkuNotFoundCount;
                totalArtSkuExistFoundCount.value = data.data.totalArtSkuExistFoundCount;
                totalDuplicateArtSkuFound.value = data.data.totalDuplicateArtSkuFound;
                active.value = 1;
                if(data.data.totalImportArtSkuCount == 0){
                  nextBtnDisabled.value = true;
                }else{
                  nextBtnDisabled.value = false;
                }
              }
              if(data.data.artSkuFileName){
                sessionStorage.setItem("artSkuFileName", data.data.artSkuFileName);
                sessionStorage.setItem("artSkuFileExt", data.data.artSkuFileExt);
              }
              disabled.value = false;
              loading.value = false;
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            loading.value = false;
          });
        }
        if(active.value == 1){
          loading.value = true;
          disabled.value = true;
          let formData = new FormData();
          formData.append('file', file.value);
          formData.append('steps', active.value);
          formData.append('updateDataCheckbox', updateDataCheckbox.value == true ? '1' : '0');
          if(sessionStorage.getItem("artSkuFileName")){
            formData.append('artSkuFileName', sessionStorage.getItem("artSkuFileName"));
          }
          if(sessionStorage.getItem("artSkuFileExt")){
            formData.append('artSkuFileExt', sessionStorage.getItem("artSkuFileExt"));
          }
          axios.post('art-sku/import', formData, config)
          .then(({ data }) => {
              notificationFire(data.message, "success");
              disabled.value = false;
              loading.value = false;
              active.value = 3;
              btnHide.value = 'd-none';
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            loading.value = false;
          });
        }
      });
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name": constants.general.BACK , 'action': 'back','class': 'btn-primary','isLoader':false, 'iconClass':"bi bi-arrow-left fs-4" }]);
    }

    //Set breadcrumbs and get the products
    onMounted(() => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": formatText('art-SKU'), "path": "art-SKU"}, { name: formatText(route.name), active: true }]);
      generateHeaderToolbarButtons();
    })

    return {
      file,
      btnHide,
      loading,
      rules,
      active,
      disabled,
      back,
      importArtSkuData,
      artSkuFormRef,
      artSkuImportFormData,
      handleChange,
      handleRemove,
      constants,
      fileList,
      totalArtSkuCount,
      totalImportArtSkuCount,
      totalArtSkuNotFoundCount,
      totalArtSkuExistFoundCount,
      totalDuplicateArtSkuFound,
      downloadSampleFile,
      nextBtnDisabled,
      updateDataCheckbox
    };
  },
};
</script>
<style>
  .upload-exel .el-upload-dragger{border:none !important;height:auto;padding:0 !important;}
  .import-form-item .el-form-item__content {
    justify-content: center;
  }
  .import-form-item .el-form-item__error {
    right: 0;
  }
  .steps .el-step__icon{
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .steps .el-step__head.is-process{
    color: #009ef7;
    font-weight:700;
  }
  .steps .el-step.is-horizontal .el-step__line{
    top:50%;
    display: block;
  }
  .steps .el-step__head.is-process {  
  border-color: #009ef7;
  }
  .steps .el-step__main{
    color: #009ef7;
  }
  .steps .el-step__title.is-process{
    color: #009ef7 !important;
  }
  .steps  .el-step__title.is-success {
    color:#009ef7;
    font-weight: 700;
  }
  .steps .el-step__head.is-success {
    color:#fff;
  }
  .steps  .el-step__head.is-success .el-step__line{
    background-color: #009ef7;
  }
  .steps  .el-step__head.is-success .el-step__icon{
    border-color: #009ef7;
    background-color: #009ef7;
  }
  .steps  .el-step__head.is-success{
    border-color: #009ef7;
  }
  .steps .form-check-input {
    width: 1.30em;
    height: 1.30rem;
    margin-right: 10px;
  }
</style>